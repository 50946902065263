import React from "react";

// External components
import Helmet from "react-helmet";

const SEO = ({ title = "", imageUrl = "", description = "" }) => {
    return (
        <Helmet>
            <title>{title.replace(/<[^>]*>/g, "")}</title>
            <meta property="og:title" content={title.replace(/<[^>]*>/g, "")} />
            <meta
                name="twitter:title"
                content={title.replace(/<[^>]*>/g, "")}
            />
            <meta property="og:image" content={imageUrl} />
            <meta name="twitter:image" content={imageUrl} />
            <meta property="og:description" content={description} />
            <meta name="twitter:description" content={description} />
            <meta name="description" content={description} />
        </Helmet>
    );
};

export default SEO;
