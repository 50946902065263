import React, { useCallback, useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

// Import routes
import Home from "routes/Home";
import SectionArticles from "routes/SectionArticles";
import SingleArticle from "routes/SingleArticle";
import NotFound from "routes/NotFound";
import Registration from "routes/Registration";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";
import Loader from "components/Loader";

const AllRoutes = ({ key }) => {
    const { setCurrentRoute, routes, data } = useContext(UmbracoContext);
    const { pathname } = useLocation();
    const updateCurrentRoute = useCallback(
        (route) => {
            setCurrentRoute(route);
        },
        [setCurrentRoute],
    );

    useEffect(() => {
        if (routes) {

            let filtered = routes?.filter(r => r.url === pathname);
            if (filtered?.length > 0) {
                updateCurrentRoute(filtered[0]);
            }
        }
    }, [pathname, routes, updateCurrentRoute]);


    const [loaded, hasLoaded] = useState(null);

    return (
        <>
            <Loader
                endpoint={'/headless/data'}
                setState={hasLoaded}
                query='data'
            >
                {loaded && data.pages && <Routes>
                    {/* /headless/data */}
                    {Array.isArray(data.pages) &&
                        data.pages.map(
                            (page) =>
                                page.title && (
                                    <Route
                                        path={page.url}
                                        exact
                                        key={page.key + "route"}
                                        element={
                                            <SectionArticles
                                                title={page.title}
                                                page={page}
                                                key={page.key + "page"}
                                                hideBrElementsOnMobile={page.hideBrElementsOnMobile}
                                                seoTitle={page.seoTitle || ""}
                                                seoDescription={page.seoDescription || ""}
                                                seoImage={page.seoImage}
                                                preTitle={page.preTitle || ""}
                                                borderColor={page.borderColor || ""}
                                                articles={page.articles}
                                                sectionColor={page.sectionColor || ""}
                                                textColor={page.textColor || ""}
                                                id={page.id}
                                            />
                                        }
                                    />

                                )
                        )}
                    {Array.isArray(data.pages) &&
                        data.pages.map((page) => (
                            <Route
                                path={page.url + "/:slug"}
                                key={page.key + "route"}
                                element={
                                    <SingleArticle
                                        pageId={page.id}
                                        sectionColor={page.sectionColor}
                                        textColor={page.textColor}
                                        borderColor={page.borderColor}
                                    />}
                            />
                        ))}
                    <Route
                        path="/registrering"
                        element={<Registration />}
                        key="registering-route"
                    />
                    <Route path="/" element={<Home />} key="home" />
                    <Route path="*" element={<NotFound />} key="404" />
                </Routes>}
            </Loader>
        </>
    );
};

export default AllRoutes;
