import React from "react";

// Internal components
import Split from "components/Split";

const NotFound = () => {
    return (
        <>
            <Split
                title="404 - siden ble ikke funnet"
                largeTitle={true}

                borderColor="#ffcc05"
                border={true}
            />
        </>
    );
};

export default NotFound;
