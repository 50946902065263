import React from "react";

// External components
import SVG from "react-inlinesvg";

// Icons
import arrow from "assets/link-arrow.svg";

const LinkArrow = ({ size = "1rem", className }) => {
    return <SVG src={arrow} width={size} className={className}></SVG>;
};

export default LinkArrow;
