import React from "react";

const AngleRight = ({ size = "1rem", color, className, style }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2.85 4.67"
            width={size}
            fill={color || "currentColor"}
            className={className}
            style={style}
        >
            <path d="M2.85,2.34.62,4.56a.36.36,0,0,1-.51,0,.35.35,0,0,1,0-.51L1.83,2.34.12.62a.36.36,0,0,1,0-.51.35.35,0,0,1,.51,0Z" />
        </svg>
    );
};

export default AngleRight;
