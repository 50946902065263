import React from "react";
import { Link } from "react-router-dom";

// Internal components
import LongArrow from "components/icons/LongArrow";
import ArrowLeft from "components/icons/ArrowLeft";
import ArrowRight from "components/icons/ArrowRight";
import LinkArrow from "components/icons/LinkArrow";

const InternalOrExternalLink = ({
    children,
    to = "",
    routePrefix = "",
    style,
    className = "",
    ariaLabel = "",
    arrow = false,
    rounded = false,
    borderColor,
    next = false,
    previous = false,
    color,
    lg = false,
    link = false,
    sm = false,
}) => {
    if (to.startsWith("/")) {
        return (
            <Link
                to={routePrefix ? routePrefix + to : to}
                className={`${className} btn
                    ${rounded ? "btn--rounded" : ""}
                    ${lg && !sm ? "btn--lg" : ""}
                    ${sm && !lg ? "btn--sm" : ""}
                    ${next && !previous ? "btn--next" : ""}
                    ${previous && !next ? "btn--previous" : ""}
                    ${link ? "btn--link" : ""}
                `}
                style={{ ...style, color: color, borderColor: borderColor }}
                aria-label={ariaLabel}
                title={ariaLabel}
            >
                {link && <LinkArrow className="btn_icon-link" color="currentColor" />}
                {previous && <ArrowLeft className="btn_icon-previous" color="currentColor" />}
                {arrow && <LongArrow className="btn_icon-longarrow" color="currentColor" />}
                {children}
                {next && <ArrowRight className="btn_icon-next" color="currentColor" />}
            </Link>
        );
    } else if (to) {
        return (
            <a
                href={to}
                target="_blank"
                rel="noreferrer"
                style={{ ...style, color: color, borderColor: borderColor }}
                aria-label={ariaLabel}
                title={ariaLabel}
                className={`${className} btn
                    ${rounded ? "btn--rounded" : ""}
                    ${lg && !sm ? "btn--lg" : ""}
                    ${sm && !lg ? "btn--sm" : ""}
                    ${next && !previous ? "btn--next" : ""}
                    ${previous && !next ? "btn--previous" : ""}
                    ${link ? "btn--link" : ""}
                `}
            >
                {link && <LinkArrow className="btn_icon-link" color="currentColor" />}
                {previous && <ArrowLeft className="btn_icon-previous" color="currentColor" />}
                {arrow && <LongArrow className="btn_icon-longarrow" color="currentColor" />}
                {children}
                {next && <ArrowRight className="btn_icon-next" color="currentColor" />}
            </a>
        );
    } else {
        return (<span
            style={{ ...style, color: color, borderColor: borderColor }}
            aria-label={ariaLabel}
            title={ariaLabel}
            className={`${className} btn
                    ${rounded ? "btn--rounded" : ""}
                    ${lg && !sm ? "btn--lg" : ""}
                    ${sm && !lg ? "btn--sm" : ""}
                    ${next && !previous ? "btn--next" : ""}
                    ${previous && !next ? "btn--previous" : ""}
                    ${link ? "btn--link" : ""}
                `}
        >
            {link && <LinkArrow className="btn_icon-link" color={color} />}
            {previous && <ArrowLeft color={color} />}
            {next && <ArrowRight color={color} />}
            {arrow && <LongArrow className="btn_icon-left" color={color} />}
            {children}
        </span>);
    }
};

export default InternalOrExternalLink;
