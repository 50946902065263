import React from "react";
import InternalOrExternalLink from "./Btn";
import { useLocation } from 'react-router-dom';
// import InternalOrExternalLink from "./Btn";

const Logo = ({ size = "8rem", className, color = "" }) => {
    const { pathname } = useLocation();
    if (pathname === "/") {
        return (
            <div to="/" id="logo" onClick={() => window.location.reload()} tabIndex="0">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 25.38 3"
                    width={size}
                    className={className}
                    fill={color || "currentColor"}
                >
                    <path d="M1.43.58V2.94H.75V.58H0V.05H2.18V.58Z" />
                    <path d="M3.87,3A1.45,1.45,0,0,1,2.33,1.5,1.45,1.45,0,0,1,3.87,0,1.45,1.45,0,0,1,5.41,1.5,1.45,1.45,0,0,1,3.87,3Zm0-2.42a.84.84,0,0,0-.85.9.85.85,0,1,0,1.7,0A.84.84,0,0,0,3.87.58Z" />
                    <path d="M6,2.94V.05h.67V2.38h1v.56Z" />
                    <path d="M8.19,2.94V.05h.68V2.38h1v.56Z" />
                    <path d="M10.39,2.94V.05h1.88V.59h-1.2v.59h1v.54h-1v.67h1.24v.55Z" />
                    <path d="M14.12.58V2.94h-.68V.58h-.75V.05h2.17V.58Z" />
                    <path d="M16.93,2.94l-.21-.58h-1l-.2.58h-.71L15.87,0h.71l1.09,2.9ZM16.21.79l-.36,1h.72Z" />
                    <path d="M19,.58V2.94h-.68V.58h-.75V.05h2.18V.58Z" />
                    <path d="M20.21,2.94V.05h1.88V.59h-1.2v.59h1v.54h-1v.67h1.24v.55Z" />
                    <path d="M24.68,2.94,23.41,1s0,.35,0,.65V2.94h-.66V.05h.72l1.25,2s0-.33,0-.64V.05h.67V2.94Z" />
                </svg>
            </div>
        );
    }
    else {
        return (
            <InternalOrExternalLink to="/" id="logo" tabIndex="0">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 25.38 3"
                    width={size}
                    className={className}
                    fill={color || "currentColor"}
                >
                    <path d="M1.43.58V2.94H.75V.58H0V.05H2.18V.58Z" />
                    <path d="M3.87,3A1.45,1.45,0,0,1,2.33,1.5,1.45,1.45,0,0,1,3.87,0,1.45,1.45,0,0,1,5.41,1.5,1.45,1.45,0,0,1,3.87,3Zm0-2.42a.84.84,0,0,0-.85.9.85.85,0,1,0,1.7,0A.84.84,0,0,0,3.87.58Z" />
                    <path d="M6,2.94V.05h.67V2.38h1v.56Z" />
                    <path d="M8.19,2.94V.05h.68V2.38h1v.56Z" />
                    <path d="M10.39,2.94V.05h1.88V.59h-1.2v.59h1v.54h-1v.67h1.24v.55Z" />
                    <path d="M14.12.58V2.94h-.68V.58h-.75V.05h2.17V.58Z" />
                    <path d="M16.93,2.94l-.21-.58h-1l-.2.58h-.71L15.87,0h.71l1.09,2.9ZM16.21.79l-.36,1h.72Z" />
                    <path d="M19,.58V2.94h-.68V.58h-.75V.05h2.18V.58Z" />
                    <path d="M20.21,2.94V.05h1.88V.59h-1.2v.59h1v.54h-1v.67h1.24v.55Z" />
                    <path d="M24.68,2.94,23.41,1s0,.35,0,.65V2.94h-.66V.05h.72l1.25,2s0-.33,0-.64V.05h.67V2.94Z" />
                </svg>
            </InternalOrExternalLink>
        );
    }
};

export default Logo;
