import React from 'react';

import InnerHTML from 'dangerously-set-html-content';
const SocialFeedEmbedded = ({ page }) => {

    return (
        <div style={{ padding: "1rem" }}>
            <InnerHTML html={page.embedCode} />
        </div>
    );
};

export default SocialFeedEmbedded;