import React from 'react';

// Import styles
import './sass/main.scss';

// Contexts
import { UmbracoProvider } from 'contexts/Umbraco';
import SimpleReactLightbox from '@tjjjwxzq/simple-react-lightbox';

// App
import App from './App';

import { createRoot } from 'react-dom/client';

import { QueryClient, QueryClientProvider } from 'react-query';
const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <QueryClientProvider client={queryClient}>
    <UmbracoProvider>
      <SimpleReactLightbox>
        <App />
      </SimpleReactLightbox>
    </UmbracoProvider>
  </QueryClientProvider>
);
