import React, { useContext, useEffect, useRef, useState } from "react";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

// Internal components
import Title from "components/Title";
import ScrollDown from "./ScrollDown";

// External components
import useScrollPercentage from "hooks/useScrollPercentage";

const Split = ({
    title,
    children,
    borderColor,
    text,
    scrollDown = "",
    largeTitle = false,
    fullHeight = true,
    sectionColor,
    textColor,
    forceColor, h1 = false
}) => {
    const { data, screenWidth, menuOpen } = useContext(UmbracoContext);
    const { ref, percentage } = useScrollPercentage();
    useEffect(() => {
        let perc = percentage;
        if (!menuOpen && perc) {
            if (
                perc.toPrecision(1) * 10 > 3 &&
                perc.toPrecision(1) * 10 < 7 &&
                document.body.style.background !== sectionColor
            ) {
                document.body.style.background = sectionColor;
            }
            if (
                perc.toPrecision(1) * 10 > 3 &&
                perc.toPrecision(1) * 10 < 7
            ) {
                if (
                    textColor !== "" &&
                    document.getElementById("nav").style.color !== textColor
                ) {
                    document.getElementById("nav").style.color = textColor;
                    document.getElementById("nav").style.borderColor =
                        textColor;
                    if (
                        screenWidth < 1000 &&
                        document.getElementById("nav-wrapper").style
                            .backgroundColor !== sectionColor
                    ) {
                        document.getElementById(
                            "nav-wrapper"
                        ).style.backgroundColor = sectionColor;
                    } else if (
                        screenWidth > 1000 &&
                        document.getElementById("nav-wrapper").style
                            .backgroundColor !== "transparent"
                    ) {
                        document.getElementById(
                            "nav-wrapper"
                        ).style.backgroundColor = "transparent";
                    }
                } else if (
                    document.getElementById("nav").style.color !== "inherit"
                ) {
                    document.getElementById("nav").style.color = "inherit";
                    document.getElementById(
                        "nav-wrapper"
                    ).style.backgroundColor = "transparent";
                    document.getElementById("nav").style.borderColor =
                        "inherit";
                }
            }
        }
    }, [percentage, sectionColor, textColor, data, menuOpen, screenWidth]);
    const titleRef = useRef(null);
    const [borderHeight, setBorderHeight] = useState(null);
    useEffect(() => {
        if (titleRef.current) {
            setBorderHeight(titleRef.current.clientHeight);
        }
    }, [titleRef]);
    return (
        <>
            <section
                ref={ref}
                style={{
                    color: textColor,
                }}
                className={`split ${fullHeight ? "split--fullheight" : ""}`}
            >
                {/* {percentage.toPrecision(1) * 10} */}
                {/* {percentage.toPrecision(1) * 10} */}
                <div className="container">
                    <div
                        className={`split_grid ${!text
                            }`}
                    >
                        <div ref={titleRef} className="split_title">
                            <Title h1={h1}
                                borderBottom={screenWidth < 1000 ? true : false}
                                // lgOnSm={true}
                                borderColor={borderColor || data.mainColor}
                                largeTitle={largeTitle}
                                title={title}
                            ></Title>
                        </div>
                        <div
                            className="split_border"
                            style={{
                                background: borderColor,
                                height: borderHeight * 0.9,
                            }}
                        ></div>
                        <div>
                            {text && (
                                <div
                                    className="split_text"
                                    dangerouslySetInnerHTML={{ __html: text }}
                                ></div>
                            )}
                            {children && (
                                <div
                                    className="split_text"
                                    style={{
                                        borderColor: borderColor || data.mainColor,
                                    }}
                                >
                                    {children}
                                </div>
                            )}
                        </div>
                    </div>
                    {scrollDown !== "" && (
                        <ScrollDown className="scrolldown--bottom-left">{scrollDown || "scroll ned"}</ScrollDown>
                    )}
                </div>
            </section>
        </>
    );
};

export default Split;
