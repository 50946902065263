import React, { useCallback, useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Keyboard, Mousewheel, Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/keyboard';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const Scrollable = ({ returnActiveIndex, sections }) => {

    const [activeIndex, setActiveIndex] = useState(0);

    const updateActiveIndex = useCallback(
        (val) => {
            returnActiveIndex(val);
        },
        [returnActiveIndex],
    );

    useEffect(() => {
        updateActiveIndex(activeIndex);
    }, [activeIndex, updateActiveIndex]);
    const setColorAndIndex = useCallback(
        (e) => {
            let color = document.querySelector(".swiper-slide.swiper-slide-active")?.querySelector("[data-color]")?.getAttribute("data-color");
            // let bgColor = document.querySelector(".swiper-slide.swiper-slide-active")?.querySelector("[data-bg-color]")?.getAttribute("data-bg-color");
            document.querySelector(':root').style.setProperty('--section-text-color', color);
            // console.log(e);
            document.querySelector('#nav').style.color = color;
            document.querySelector('.scrolldown').style.color = color;
            // document.querySelector('nav-wrapper').style.backgroundColor = bgColor;
            setActiveIndex(e.activeIndex);
        },
        [],
    );

    return (
        <div className='section-swiper'>
            <div>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    onSlideChangeTransitionEnd={(e) => {
                        setColorAndIndex(e);
                    }}
                    onSwiper={(e) => {
                        setColorAndIndex(e);
                    }}
                    direction="vertical"
                    scrollbar={false}
                    speed={150}
                    height={window.innerHeight}
                    modules={[Pagination, Keyboard, Mousewheel, Navigation]}
                    // navigation={
                    //     {
                    //         nextEl: '.swiper-button-next',
                    //         prevEl: '.swiper-button-prev',
                    //     }
                    // }
                    keyboard={
                        {
                            enabled: true,
                            onlyInViewport: false,
                        }
                    }
                    pagination={
                        {
                            el: '.swiper-pagination',
                            type: 'bullets',
                            clickable: true
                        }
                    }
                    mousewheel={
                        {
                            invert: false,
                            releaseOnEdges: false,
                            sensitivity: 1,
                            thresholdDelta: null,
                            thresholdTime: null,
                            forceToAxis: false,
                            eventsTarget: ".section-swiper"
                        }
                    }
                >
                    {sections?.map(s =>
                        <SwiperSlide>
                            {s}
                        </SwiperSlide>)}
                </Swiper>
                <div className='swiper-pagination'></div >
            </div>
        </div >
    );
};

export default Scrollable;