import React from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Keyboard, Mousewheel, Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/keyboard';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import LongArrow from "components/icons/LongArrow";

const Article = ({ a }) => <div className='featured-articles__article'>
    {a.image && <img className='featured-articles__article__image' src={a.image.url} alt={a.image.altText?.length > 0 ? a.image.altText : a.image.title} />}
    <div className='featured-articles__article__body'>
        {a.title && <h3 className='featured-articles__article__title'>{a.title}</h3>}
        {a.text && <div className='featured-articles__article__text rich-text' dangerouslySetInnerHTML={{ __html: a.text }} />}
    </div>
</div>;

const LinkedArticle = ({ a, isSlider }) => <div className='featured-articles__article featured-articles__article--linked'>
    {(a.image || a.title) && <a href={a.link.url} target={a.link.target} className="featured-articles__article__link-wrapper">
        {a.image &&
            <img className='featured-articles__article__image' src={a.image.url} alt={a.image.altText?.length > 0 ? a.image.altText : a.image.title} />

        }
        {a.title && isSlider &&
            <span className={`featured-articles__article__title btn`}><LongArrow className="btn_icon-longarrow" color="currentColor" /> {a.title}</span>
        }
        {a.title && !isSlider &&
            <h3 className={`featured-articles__article__title featured-articles__article__body__title-link`}>{a.title}<LongArrow className="btn_icon-longarrow" color="currentColor" /></h3>
        }
    </a>
    }
    <div className='featured-articles__article__body'>
        {a.title && isSlider && <h3 className='featured-articles__article__title'>{a.title}</h3>}

        {a.text && <div className='featured-articles__article__text rich-text' dangerouslySetInnerHTML={{ __html: a.text }} />}
    </div >
</div >;

const Slider = ({ children }) => {
    return (<div className='featured-articles-slider'>
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            // onSlideChangeTransitionEnd={(e) => {
            //     setColorAndIndex(e);
            // }}
            // onSwiper={(e) => {
            //     setColorAndIndex(e);
            // }}
            direction="horizontal"
            scrollbar={false}
            speed={150}
            height={window.innerHeight}
            modules={[Pagination, Keyboard, Mousewheel, Navigation]}
            // navigation={
            //     {
            //         nextEl: '.swiper-button-next',
            //         prevEl: '.swiper-button-prev',
            //     }
            // }

            navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            }}
            keyboard={
                {
                    enabled: true,
                    onlyInViewport: false,
                }
            }
            pagination={
                {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                }
            }
            mousewheel={
                {
                    invert: false,
                    releaseOnEdges: false,
                    sensitivity: 1,
                    thresholdDelta: null,
                    thresholdTime: null,
                    forceToAxis: false,
                    eventsTarget: ".section-swiper"
                }
            }
        >
            {children?.map(s =>
                <SwiperSlide>{s}</SwiperSlide>)}
        </Swiper>
        <div className="swiper-navigation">
            <button className='swiper-button-prev'></button>
            <button className='swiper-button-next'></button>
        </div >
        <div className='swiper-pagination'></div >
    </div>);
};

const FeaturedArticles = ({ articles, sliderView }) => {
    let slider = sliderView;

    return (
        <div className='featured-articles-container'>
            {slider ?
                <Slider>
                    {articles?.map(a =>
                        a.link ?
                            <LinkedArticle a={a} isSlider={slider} />
                            :
                            <Article a={a} />

                    )}
                </Slider>
                :
                <div className='featured-articles'>
                    {articles?.map(a =>
                        a.link ?
                            <LinkedArticle a={a} isSlider={slider} />
                            :
                            <Article a={a} />

                    )}
                </div>
            }
        </div>
    );
};

export default FeaturedArticles;