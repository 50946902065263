import React from "react";

// Internal components
import Title from "components/Title";
import Btn from "components/Btn";
import ScrollDown from "./ScrollDown";
import Illustration from "components/Illustration";

// External components
// import { UmbracoContext } from "contexts/Umbraco";
import InternalOrExternalLink from "components/Btn";

const Section = ({
    preTitle,
    title,
    sectionColor,
    borderColor,
    textColor,
    btn,
    // slug,
    illustration,
    // sneakPeak = true,
    largeTitle = false,
    scrollDown = "",
    children,
    lgOnSm = false,
    // btnDefaultText,
    fullWidthIllustration = false,
    forceLastFrameLottie, active, hideBrElementsOnMobile
}) => {
    // const { data, menuOpen, screenWidth } = useContext(UmbracoContext);

    // Set text colors and backgrounds on load
    // const [pageColorSet, setPageColorSet] = useState(false);
    // useEffect(() => {
    //     if (!pageColorSet) {
    //         if (document.body.style.background !== sectionColor) {
    //             document.body.style.background = sectionColor;
    //         }
    //         if (document.getElementById("nav").style.color !== textColor) {
    //             document.getElementById("nav").style.color = textColor;
    //         }
    //         setPreviousTextColor(textColor);
    //         setPageColorSet(true);
    //     }
    // }, [pageColorSet, textColor, sectionColor]);

    // // Change colors and backgrounds on scroll
    // useEffect(() => {
    //     if (!menuOpen) {
    //         // On menu close, set nav wrapper background
    //         if (
    //             screenWidth < 1000 &&
    //             document.getElementById("nav-wrapper").style.backgroundColor !==
    //                 sectionColor
    //         ) {
    //             document.getElementById(
    //                 "nav-wrapper"
    //             ).style.backgroundColor = sectionColor;
    //         }
    //         // On menu close set textColor
    //         if (
    //             textColor !== "" &&
    //             document.getElementById("nav").style.color !== textColor
    //         ) {
    //             document.getElementById("nav").style.color = textColor;
    //             document.getElementById("nav").style.borderColor = textColor;
    //         }
    //     }
    // }, [menuOpen]);
    // useEffect(() => {
    //     if (!menuOpen) {
    //         if (
    //             percentage.toPrecision(1) * 10 > 1 &&
    //             percentage.toPrecision(1) * 10 < 7 &&
    //             document.body.style.background !== sectionColor
    //         ) {
    //             document.body.style.background = sectionColor;
    //         }
    //         if (
    //             percentage.toPrecision(1) * 10 > 1 &&
    //             percentage.toPrecision(1) * 10 < 7
    //         ) {
    //             if (
    //                 textColor !== "" &&
    //                 document.getElementById("nav").style.color !== textColor
    //             ) {
    //                 document.getElementById("nav").style.color = textColor;
    //                 document.getElementById(
    //                     "nav"
    //                 ).style.borderColor = textColor;
    //                 if (
    //                     screenWidth < 1000 &&
    //                     document.getElementById("nav-wrapper").style
    //                         .backgroundColor !== sectionColor
    //                 ) {
    //                     document.getElementById(
    //                         "nav-wrapper"
    //                     ).style.backgroundColor = sectionColor;
    //                 } else if (
    //                     screenWidth > 1000 &&
    //                     document.getElementById("nav-wrapper").style
    //                         .backgroundColor !== "transparent"
    //                 ) {
    //                     document.getElementById(
    //                         "nav-wrapper"
    //                     ).style.backgroundColor = "transparent";
    //                 }
    //             } else if (
    //                 document.getElementById("nav").style.color !== "inherit"
    //             ) {
    //                 document.getElementById("nav").style.color = "inherit";
    //                 document.getElementById(
    //                     "nav-wrapper"
    //                 ).style.backgroundColor = "transparent";
    //                 document.getElementById("nav").style.borderColor =
    //                     "inherit";
    //             }
    //         } else {
    //             if (data.pages) {
    //                 let currentIndex = data.pages.findIndex(
    //                     (d) => slugify(d.slug) === slugify(slug)
    //                 );
    //                 if (data.pages[currentIndex - 1]) {
    //                     setPreviousTextColor(
    //                         `${data.pages[currentIndex - 1].textColor !== null
    //                             ? data.pages[currentIndex - 1].textColor
    //                             : textColor
    //                         }`
    //                     );
    //                 }
    //             }
    //         }
    //     }
    // }, [
    //     percentage,
    //     sectionColor,
    //     textColor,
    //     slug,
    //     data,
    //     menuOpen,
    //     screenWidth,
    // ]);
    // const [previousTextColor, setPreviousTextColor] = useState();
    return (
        <section

            style={{
                color:
                    textColor,
                backgroundColor: sectionColor
            }}
            data-color={textColor}
            bg-color={sectionColor}
            className={`section`}
        >

            <div className="container">
                <InternalOrExternalLink
                    to={btn.to.url}
                    className="section-link-wrapper"
                    style={{ color: "inherit", textDecoration: "none" }}
                >
                    <div className={`${largeTitle ? "" : "section_grid"}`}>
                        <div>
                            <div>
                                <InternalOrExternalLink
                                    to={btn.to.url}>
                                    <span>
                                        <Title
                                            hideBrElementsOnMobile={hideBrElementsOnMobile}
                                            preTitle={preTitle}
                                            borderColor={borderColor}
                                            largeTitle={largeTitle}
                                            lgOnSm={lgOnSm}
                                            title={title}
                                        ></Title>
                                    </span>
                                </InternalOrExternalLink >
                            </div>
                            <div>
                                {btn && btn.to && (
                                    <Btn
                                        to={btn.to.url}
                                        ariaLabel={btn.ariaLabel}
                                        arrow
                                        className="section_btn-above"
                                    >
                                        {btn.to.label}
                                    </Btn>
                                )}
                            </div>
                        </div>
                        {illustration !== undefined && (
                            <InternalOrExternalLink
                                to={btn.to.url}
                                className={`section_illustration ${fullWidthIllustration
                                    ? "illustration--fullwidth"
                                    : ""
                                    }`}
                            >
                                <Illustration
                                    content={illustration}
                                    play={active}
                                    // scrolled={percentage}
                                    noScrollyTelling
                                    forceLastFrameLottie={forceLastFrameLottie}
                                />
                            </InternalOrExternalLink>
                        )}
                    </div>
                </InternalOrExternalLink>
                {children}
                {scrollDown !== "" && (
                    <ScrollDown>{scrollDown || "scroll ned"}</ScrollDown>
                )}
            </div>
        </section>
    );
};

export default Section;
