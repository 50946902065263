import React from "react";

const ScrollDown = ({ children, className }) => {
    return (
        <div className={`scrolldown ${className}`}>
            <span>{children}</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.21 9.26">
                <path
                    d="M1.6,5.74A1.6,1.6,0,0,1,0,4.14V1.6a1.6,1.6,0,0,1,3.2,0V4.14A1.6,1.6,0,0,1,1.6,5.74ZM1.6.5A1.1,1.1,0,0,0,.5,1.6V4.14a1.1,1.1,0,0,0,2.2,0V1.6A1.1,1.1,0,0,0,1.6.5Z"
                    fill="#2e2d2c"
                />
                <path
                    d="M1.6,7.82.07,6.28a.26.26,0,0,1,0-.35.25.25,0,0,1,.36,0L1.6,7.11,2.78,5.94a.25.25,0,0,1,.36,0,.26.26,0,0,1,0,.35Z"
                    fill="#2e2d2c"
                />
                <path
                    d="M1.6,9.26.07,7.72a.26.26,0,0,1,0-.35.25.25,0,0,1,.36,0L1.6,8.55,2.78,7.38a.25.25,0,0,1,.36,0,.26.26,0,0,1,0,.35Z"
                    fill="#2e2d2c"
                />
                <circle cx="1.6" cy="1.58" r="0.41" fill="#2e2d2c" />
            </svg>
        </div>
    );
};

export default ScrollDown;
