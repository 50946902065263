import React, { useEffect } from "react";

// Internal components
import Title from "components/Title";

// External components
import useScrollPercentage from "hooks/useScrollPercentage";

const Article = ({ title, sectionColor, children, className, borderColor }) => {
    useEffect(() => {
        document.getElementById("nav").style.color = "inherit";
    }, []);
    const [ref, percentage] = useScrollPercentage({
        /* Optional options */
        threshold: 0,
    });
    useEffect(() => {
        if (
            percentage.toPrecision(1) * 10 > 1 &&
            percentage.toPrecision(1) * 10 < 7
        ) {
            document.getElementById("nav-wrapper").style.backgroundColor =
                "#fff";
        }
    }, [percentage]);

    useEffect(() => {
        document.body.classList.remove("disable-scroll");
    }, []);
    return (
        <article className={`article ${className}`} ref={ref}>
            <div className="container article-title">
                <Title
                    largeTitle={true}
                    borderColor={borderColor}
                    title={title}
                    h1={true}
                >
                    {title}
                </Title>
            </div>
            {children}
        </article>
    );
};

export default Article;
