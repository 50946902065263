import React from "react";

// External components
import Lottie from "react-lottie-segments";
import Image from "./Image";

const Illustration = ({
    content,
    sectionColor,
    noScrollyTelling = false, play = false
}) => {
    return (
        <>
            {content && content.type !== undefined && (
                <div className="illustration-wrapper">
                    {content.type.toLowerCase() === "lottieimage" &&
                        content.jsonFile &&
                        content.jsonFile.url &&
                        !content.controlledByScroll && (
                            <div className="illustration">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        path: content.jsonFile.url,
                                        rendererSettings: {
                                            preserveAspectRatio:
                                                "xMidYMid meet",
                                        },
                                    }}
                                    isPaused={!play}
                                    isStopped={!play}
                                    // height="800"
                                    // height={screenWidth < 800 ? "250" : "600"}
                                    // width="auto"
                                    key={content.jsonFile.key}
                                    className="illustration"
                                />
                            </div>
                        )}
                    {content.type.toLowerCase() === "lottieimage" &&
                        content.jsonFile &&
                        content.jsonFile.url &&
                        content.controlledByScroll && (
                            <div className="illustration">
                                <Lottie
                                    options={{
                                        loop: false,
                                        path: content.jsonFile.url,
                                        rendererSettings: {
                                            preserveAspectRatio:
                                                "xMidYMid meet",
                                        },
                                    }}
                                    key={content.jsonFile.key}
                                    // height="800"
                                    // height={
                                    //     screenWidth < 800 ? "250" : "600"
                                    // }
                                    // width="auto"
                                    isPaused={!play}
                                    isStopped={!play}
                                // goToAndStop={frame}
                                />
                                <div
                                    style={{ opacity: 0 }}
                                    // ref={ref}
                                    className="illustration-scroller"
                                ></div>
                            </div>
                        )}
                    {content.type.toLowerCase() === "mediaitem" &&
                        content.image &&
                        content.image.url && (
                            <div className="illustration">
                                <Image
                                    url={content.image.url}
                                    altText={content.image.altText}
                                    credit={content.image.credit}
                                    scrollyTelling={
                                        noScrollyTelling !== true
                                            ? content.scrollytelling
                                            : false
                                    }
                                    scrollyTellingText={
                                        content.scrollytellingTekst
                                    }
                                    sectionColor={sectionColor}
                                />
                            </div>
                        )}
                </div>
            )}
        </>
    );
};

export default Illustration;
