import React, { useEffect, useRef } from "react";

// External components
import ReactVivus from "react-vivus";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import Delay from "react-delay";
import CountUp from "react-countup";

function FadeInWhenVisible({ children, className }) {
    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    return (
        <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            transition={{ duration: 0.3, staggerChildren: 0.7 }}
            variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
            }}
            className={className}
        >
            {children}
        </motion.div>
    );
}

const Infographic = ({ items }) => {
    const itemRef = useRef(null);
    return (
        <FadeInWhenVisible className="infographic">
            {items.map(
                (item, index) =>
                    item.type.toLowerCase() === "infographicitem" && (
                        <motion.div key={item.key + "infographic-item"}
                            className="infographic__item"
                            variants={{
                                visible: {
                                    opacity: 1,
                                },
                                hidden: { opacity: 0 },
                                transition: { duration: 0.3 },
                            }}
                            ref={itemRef}
                        >
                            <div
                                className="infographic__item__image"
                                style={{ height: 100 }}
                            >
                                {item.image && (
                                    <>
                                        {item.animateLines ? (
                                            <Delay wait={index * 1000}>
                                                <ReactVivus
                                                    id={item.key}
                                                    option={{
                                                        file: item.image.url,
                                                        animTimingFunction:
                                                            "EASE",
                                                        type: "sync",
                                                        start: "inViewport",
                                                    }}
                                                    key={item.key}
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                    }}
                                                // callback={console.log}
                                                />
                                            </Delay>
                                        ) : (
                                            <img
                                                src={item.image.url}
                                                style={{
                                                    height: "100%",
                                                    width: "100%",
                                                }}
                                                alt="infographic illustration"
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="infographic__item__content">
                                <p className="infographic__item__content_counter">
                                    {item.countTarget && (
                                        <>
                                            {item.countUp ? (
                                                <Delay
                                                    wait={index * 1000}
                                                    decimals={2}
                                                >
                                                    <CountUp
                                                        end={parseFloat(
                                                            item.countTarget
                                                        )}
                                                        decimals={
                                                            item.countTarget
                                                                .toString()
                                                                .includes(".")
                                                                ? 1
                                                                : 0
                                                        }
                                                    />
                                                </Delay>
                                            ) : (
                                                item.countTarget
                                            )}
                                        </>
                                    )}
                                </p>
                                {item.textContent && (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: item.textContent,
                                        }}
                                        className="infographic__item__content_text"
                                    ></div>
                                )}
                            </div>
                            {/* "image": {
                    "id": 1278,
                    "key": "06d8ad8e-cc0e-4846-b766-65daf8546a08",
                    "url": "/media/s0wnqbqo/piktogrammer-mastersamling_landegrense.svg",
                    "type": "file"
                },
                "animateLines": true,
                "countTarget": "2562",
                "countUp": true,
                "textContent": "<p><strong>km</strong> landegrense</p>" */}
                        </motion.div>
                    )
            )}
        </FadeInWhenVisible>
    );
};

export default Infographic;
