import React from "react";

// External components
import SVG from "react-inlinesvg";

// Icons
import arrow from "assets/long-arrow.svg";

const ArrowRight = ({ size = "1.5rem", className }) => {
    return <SVG src={arrow} width={size} className={className}></SVG>;
};

export default ArrowRight;
