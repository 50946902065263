import React, { useState, useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";

// External components
import axios from "axios";
import slugify from "react-slugify";
import useScrollPercentage from "hooks/useScrollPercentage";

// Internal components
import Article from "components/Article";
import RichText from "components/RichText";
import Video from "components/Video";
import FAQ from "components/FAQ";
// import SocialFeed from "components/SocialFeed";
import Illustration from "components/Illustration";
import SEO from "components/SEO";
import Footer from "components/Footer";
import Btn from "components/Btn";
import Infographic from "components/Infographic";
// import SocialFeedNew from "components/SocialFeed-new";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";
import FeaturedArticles from "components/FeaturedArticles";
import SocialFeedEmbedded from "components/SocialFeed-embedded";

const Components = ({ comp, sectionColor, screenWidth }) => (
    <>
        {comp.type.toLowerCase() === "video" && (
            <Video
                url={
                    screenWidth < 1000 && comp.videoUrlMobile
                        ? comp.videoUrlMobile
                        : comp.videoUrl
                }
                title={comp.title}
                key={comp.key}
                scrollyTelling={comp.scrollytelling}
                scrollyTellingVideo={comp.scrollyTellingVideo}
                scrollyTellingText={comp.scrollyTellingText}
            />
        )}
        {comp.type.toLowerCase() === "mediaitem" && comp.scrollytelling && (
            <div className="article-image">
                <Illustration
                    content={comp}
                    key={comp.key}
                    sectionColor={sectionColor}
                />
            </div>
        )}
        {(comp.type.toLowerCase() === "richtext" ||
            comp.type.toLowerCase() === "mediaitem" ||
            comp.type.toLowerCase() === "lottieimage" ||
            comp.type.toLowerCase() === "faq" ||
            comp.type.toLowerCase() === "socialfeed" ||
            comp.type.toLowerCase() === "infographic" ||
            comp.type.toLowerCase() === "button" ||
            comp.type.toLowerCase() === "featuredarticles") &&
            <div className="container">
                {comp.type.toLowerCase() === "richtext" && (
                    <div className="article-container">
                        <RichText
                            key={comp.key}
                            content={comp.content.replace(
                                /<h1>/g,
                                `<h1 style="color:${sectionColor}">`
                            ).replace(
                                /<blockquote>/g,
                                `<blockquote style="color:${sectionColor}">`
                            )}
                        />
                    </div>
                )}
                {comp.type.toLowerCase() === "mediaitem" && !comp.scrollytelling && (
                    <div className="article-image">
                        <Illustration
                            content={comp}
                            key={comp.key}
                            sectionColor={sectionColor}
                        />
                    </div>
                )}
                {comp.type.toLowerCase() === "lottieimage" && (
                    <Illustration
                        content={comp}
                        key={comp.key}
                        sectionColor={sectionColor}
                    />
                )}
                {comp.type.toLowerCase() === "faq" && (
                    <div className="article-container">
                        <FAQ
                            key={comp.key}
                            items={comp.faqList}
                            expandedColor={sectionColor}
                        />
                    </div>
                )}
                {comp.type.toLowerCase() === "socialfeed" && (
                    <SocialFeedEmbedded page={comp} key={comp.key} />
                    // <SocialFeedNew page={comp.refId} key={comp.key} />
                    // <SocialFeed page={comp.refId} key={comp.key} />
                )}
                {comp.type.toLowerCase() === "infographic" && (
                    <div className="article-container">
                        <Infographic items={comp.listItems} key={comp.key} />
                    </div>
                )}
                {comp.type.toLowerCase() === "button" && comp.to && (
                    <div className="article-container">
                        <Btn to={comp.to.url} link>
                            {comp.to.label}
                        </Btn>
                    </div>
                )}
                {/* Featured articles */}
                {comp.type.toLowerCase() === "featuredarticles" && (
                    <FeaturedArticles articles={comp.list} sliderView={comp.sliderView} />
                )}
            </div>
        }
    </>
);
const SingleArticle = ({ pageId, sectionColor, textColor, borderColor }) => {

    useEffect(() => {
        document.body.classList.remove("disable-scroll");
    }, []);
    const { screenWidth } = useContext(UmbracoContext);
    const { slug } = useParams();
    const [article, setArticle] = useState(null);
    const [nextArticle, setNextArticle] = useState(null);
    const [previousArticle, setPreviousArticle] = useState(null);
    const { pathname } = useLocation();
    useEffect(() => {
        setPreviousArticle(null);
        setNextArticle(null);
        setArticle(null);
    }, [pathname]);

    const [ref, percentage] = useScrollPercentage({
        /* Optional options */
        threshold: 0,
    });

    useEffect(() => {
        if (
            percentage.toPrecision(1) * 10 > 0 &&
            percentage.toPrecision(1) * 10 < 10
        ) {
            if (document.body.style.background !== "#fff") {
                document.body.style.background = "#fff";
            }
            if (
                document.getElementById("nav-wrapper").style.backgroundColor !==
                "#fff"
            ) {
                document.getElementById("nav-wrapper").style.backgroundColor =
                    "#fff";
            }
            if (document.getElementById("nav").style.color !== "inherit") {
                document.getElementById("nav").style.color = "inherit";
            }
            if (
                document.getElementById("nav").style.borderColor !== "inherit"
            ) {
                document.getElementById("nav").style.borderColor = "inherit";
            }
        }
    }, [percentage, sectionColor, slug]);

    // Get current article
    useEffect(() => {
        if (slug && pageId) {
            const allArticlesUrl = axios.get(`/headless/articles`);
            const sectionArticlesUrl = axios.get(`/headless/data/${pageId}`);
            axios
                .all([allArticlesUrl, sectionArticlesUrl])
                .then(
                    axios.spread((...responses) => {
                        // All full articles
                        const allArticles = responses[0];

                        // Only articles from this section (not full articles)
                        const sectionArticles = responses[1];

                        // Slugs from this section
                        let sectionFilter = sectionArticles.data.articles.map(
                            (item) => item.slug
                        );

                        // Filter by slugs from this section
                        let sectionFiltered = allArticles.data.filter((item) =>
                            sectionFilter.includes(item.slug)
                        );

                        // CURRENT ARTICLE
                        let current = sectionFiltered.filter(
                            (d) => slugify(d.slug) === slugify(slug)
                        );
                        let currentIndex = sectionFiltered.findIndex(
                            (d) => slugify(d.slug) === slugify(slug)
                        );

                        // NEXT ARTICLE
                        if (sectionFiltered[currentIndex + 1]) {
                            setNextArticle({
                                ...sectionFiltered[currentIndex + 1],
                            });
                        }

                        // PREVIOUS ARTICLE
                        if (sectionFiltered[currentIndex - 1]) {
                            setPreviousArticle({
                                ...sectionFiltered[currentIndex - 1],
                            });
                        }
                        setArticle({
                            ...current[0],
                        });
                    })
                )
                .catch((errors) => {
                    // react on errors.
                });
        }
    }, [slug, pageId]);

    useEffect(() => {
        document.body.style.background = "#fff";
        document.getElementById("nav").style.color = "#2e2d2c";
        document.getElementById("nav-wrapper").style.background = "transparent";
    }, []);
    // useEffect(() => {
    //     const handleScroll = () => {
    //         // find current scroll position
    //         const currentScrollPos = window.pageYOffset;
    //         if (currentScrollPos > prevScrollPos) {
    //             document.getElementById("nav").classList.add("nav--hide");
    //         } else {
    //             document.getElementById("nav").classList.remove("nav--hide");
    //         }

    //         // set state to new scroll position
    //         setPrevScrollPos(currentScrollPos);
    //     };
    //     window.addEventListener("scroll", handleScroll);

    //     return () => window.removeEventListener("scroll", handleScroll);
    // }, [prevScrollPos]);

    const [renderedComps, setRenderedComps] = useState(null);
    useEffect(() => {
        if (article?.components?.length > 0) {
            let filtered = article.components.filter(c => !c.hideComponent);
            if (filtered?.length > 0) {
                setRenderedComps(filtered);
            }
        }
    }, [article]);

    return (
        <>
            {article !== null && article !== undefined && (
                <>
                    <SEO
                        title={article.seoTitle || article.title}
                        description={article.seoDescription}
                        imageUrl={article.seoImage ? article.seoImage.url : ""}
                    />
                    <div ref={ref}>
                        <Article
                            title={article.title}
                            borderColor={
                                sectionColor !== "#fff"
                                    ? sectionColor
                                    : "#ffcc05"
                            }
                            sectionColor={sectionColor}
                            className={`${previousArticle || nextArticle
                                ? "article--has-arrows"
                                : ""
                                }
                        ${renderedComps && renderedComps[renderedComps.length - 1]?.type.toLowerCase() === "video" ? "article--no-margin" : ""}
                                `}
                        >
                            {previousArticle && window.location.pathname !== previousArticle.url && !previousArticle.hideInNav && (
                                <Btn
                                    className="article_btn-left"
                                    previous
                                    lg
                                    to={previousArticle.url}
                                ></Btn>
                            )}
                            {nextArticle && window.location.pathname !== nextArticle.url && !nextArticle.hideInNav && (
                                <Btn
                                    color={sectionColor}
                                    className="article_btn-right"
                                    next
                                    to={nextArticle.url}
                                    lg
                                >
                                    {nextArticle.linkToText}
                                </Btn>
                            )}
                            {renderedComps?.length &&
                                renderedComps?.map((comp) => (
                                    <Components
                                        key={comp.key}
                                        comp={comp}
                                        sectionColor={sectionColor}
                                        screenWidth={screenWidth}
                                    />
                                ))}
                            {!Array.isArray(article?.components) &&
                                Object.keys(article?.components).length && !article?.components?.hideComponent && (
                                    <Components
                                        key={article.components.key}
                                        comp={article.components}
                                        sectionColor={sectionColor}
                                        screenWidth={screenWidth}
                                    />
                                )}
                        </Article>
                    </div>
                    {/* {percentage.toPrecision(1) * 10} */}
                    <Footer
                        sectionColor={sectionColor}
                        textColor={textColor}
                        borderColor={borderColor}
                    />
                </>
            )}
        </>
    );
};

export default SingleArticle;
