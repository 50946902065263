import React, { useRef, useEffect } from "react";

const Title = ({
    title,
    preTitle,
    children,
    borderColor,
    borderBottom = true,
    lgOnSm = false,
    largeTitle = false, hideBrElementsOnMobile, h1 = false
}) => {
    const titleRef = useRef(null);
    const borderRef = useRef(null);
    // useEffect(() => {
    //     console.log(title);
    // }, [title]);
    useEffect(() => {
        // The 'current' property contains info of the reference:
        // align, title, ... , width, height, etc.
        if (titleRef.current && borderRef.current) {
            borderRef.current.style.height =
                titleRef.current.clientHeight * 0.9 + "px";
        }
    }, [titleRef, borderRef]);
    return (
        <>
            {preTitle && (
                <div
                    className={`h2 pre-title ${lgOnSm ? "pre-title--lg-on-sm" : ""
                        }
                    `}
                >
                    {preTitle}
                </div>
            )}
            {title && (
                <div
                    className={`title-wrapper ${lgOnSm ? "title-wrapper--lg-on-sm" : ""
                        } ${largeTitle ? "title-wrapper--lg" : ""}
${hideBrElementsOnMobile ? "title-wrapper--hide-br" : ""} ${title
                            .toString().length < 25 ? "title-wrapper--hide-br" : ""}`}
                >
                    {/* {largeTitle && (
                        <div
                            ref={borderRef}
                            className="title-wrapper--lg_border"
                            style={{
                                background: borderColor,
                            }}
                        ></div>
                    )} */}
                    {h1 ? <h1
                        ref={titleRef}
                        dangerouslySetInnerHTML={{
                            __html: title
                                .toString()
                                .replace(/<p>/g, "")
                                .replace(/<\/p>/g, ""),
                        }}
                        className={`title inherit-all ${largeTitle ? "title--lg-border" : ""
                            }`}
                        style={{ borderColor: borderColor }}
                    ></h1> : <p
                        ref={titleRef}
                        dangerouslySetInnerHTML={{
                            __html: title
                                .toString()
                                .replace(/<p>/g, "")
                                .replace(/<\/p>/g, ""),
                        }}
                        className={`title h1 inherit-all ${largeTitle ? "title--lg-border" : ""
                            }`}
                        style={{ borderColor: borderColor }}
                    ></p>}
                    {/* {borderBottom && (
                        <div
                            className={`title_border ${
                                largeTitle ? "title_border--lg" : ""
                            }`}
                            style={{ background: borderColor }}
                        />
                    )} */}
                </div>
            )}
        </>
    );
};

export default Title;
