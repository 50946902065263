import React, { useContext } from "react";

// Internal components
import Split from "components/Split";
import Btn from "components/Btn";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

const Footer = ({ className, textColor, sectionColor, borderColor }) => {
    const { data } = useContext(UmbracoContext);
    return (
        <footer
            className={`footer ${className}`}
            style={{ backgroundColor: sectionColor, color: textColor }}
        >
            <Split
                title={data.footerTitle}
                textColor={textColor}
                borderColor={
                    borderColor && borderColor !== sectionColor
                        ? borderColor
                        : textColor
                }
                fullHeight={false}
                sectionColor={sectionColor}
            >
                {data.footerButton && data.footerButton.to && (
                    <Btn
                        rounded
                        ariaLabel={data.footerButton.ariaLabel}
                        to={data.footerButton.to.url}
                        color={textColor}
                        borderColor={textColor}
                    >
                        {data.footerButton.to.label}
                    </Btn>
                )}
            </Split>
            {data.footerLinks?.length > 0 && <div className="footer-links">
                {data.footerLinks?.map(l => <Btn key={`${l.label}-footer-link`} target={l.target} to={l.url} title={l.label} arrow>{l.label}</Btn>)}

                {data.footerText && <div className="footer-text rich-text" dangerouslySetInnerHTML={{ __html: data.footerText }} />}
            </div>}

        </footer>
    );
};

export default Footer;
