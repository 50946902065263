import React from "react";

// Internal components
import RichText from "components/RichText";

const Image = ({
    url,
    altText,
    credit,
    sectionColor,
    scrollyTelling,
    scrollyTellingText,
    cover = false,
}) => {
    // const [ref, percentage] = useScrollPercentage({
    //     /* Optional options */
    //     threshold: 0,
    // });
    // useEffect(() => {
    //     if (
    //         percentage.toPrecision(1) * 10 > 4 &&
    //         percentage.toPrecision(1) * 10 < 10
    //     ) {
    //         document.getElementById("nav").style.color = "#FFF";
    //     } else {
    //         document.getElementById("nav").style.color = "inherit";
    //     }
    // }, [percentage]);
    return (
        <>
            {!scrollyTelling && (
                <figure className="image-container">
                    <img
                        src={url}
                        className={`image ${!url.includes("svg") || cover ? "image--notsvg" : ""
                            }`}
                        alt={altText}
                    />
                    {credit && (
                        <figcaption className="image_credit">
                            <RichText
                                content={credit.replace(
                                    /<h1>/g,
                                    `<h1 style="color:${sectionColor}">`
                                )}
                            />
                        </figcaption>
                    )}
                </figure>
            )}
            {/* {scrollyTelling && scrollyTellingText && (
                <div className="container--fullwidth-on-sm">
                    <div className="article-container article-container--fullwidth-on-sm">
                        <section className="scrollytelling-wrapper">
                            <div className="scrollytelling-bg-wrapper">
                                <div
                                    className="scrollytelling-bg"
                                    style={{ backgroundImage: `url("${url}")` }}
                                ></div>
                            </div>
                            <div
                                className="scrollytelling-content article-container"
                                dangerouslySetInnerHTML={{
                                    __html: scrollyTellingText,
                                }}
                            ></div>
                        </section>
                    </div>
                </div>
            )} */}
            {scrollyTelling && scrollyTellingText && (
                <section className="scrollytelling-wrapper">
                    <div className="scrollytelling-bg-wrapper">
                        <div
                            className="scrollytelling-bg"
                            style={{ backgroundImage: `url("${url}")` }}
                        ></div>
                    </div>
                    <div className="container">
                        <div
                            className="scrollytelling-content article-container"
                            dangerouslySetInnerHTML={{
                                __html: scrollyTellingText,
                            }}
                        ></div>
                    </div>
                </section>
            )}
        </>
    );
};

export default Image;
