import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

// Internal components
import Routes from 'Routes';
import Nav from 'components/Nav';
// import ScrollToTop from 'components/ScrollToTop';

// Contexts
import { UmbracoContext } from 'contexts/Umbraco';

// External components
import Hotkeys from 'react-hot-keys';

const App = () => {
  const { data, currentRoute } = useContext(UmbracoContext);
  useEffect(() => {
    if (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    ) {
      // dev code
      if (window.location.pathname === '/build') {
        // Redirect from build to home
        window.location = '/';
      }
    } else {
      // production code
    }
  }, []);
  return (
    <>
      <Hotkeys
        keyName='tab'
        allowRepeat={true}
        onKeyDown={() => {
          console.log(document.activeElement);
        }}></Hotkeys>
      <Hotkeys
        keyName='alt+shift+u'
        allowRepeat={true}
        onKeyDown={() =>
          window
            .open(
              `${process.env.REACT_APP_PROXY}${
                currentRoute
                  ? `/umbraco#/content/content/edit/${currentRoute?.id}`
                  : '/umbraco'
              }`,
              '_blank'
            )
            .focus()
        }></Hotkeys>
      <Hotkeys
        keyName='alt+shift+g'
        allowRepeat={true}
        onKeyDown={() =>
          window
            .open(
              `https://github.com/aksellsor/07_cra_blitoller/actions`,
              '_blank'
            )
            .focus()
        }></Hotkeys>
      {data !== null && data !== undefined && (
        <>
          <Router>
            {/* <ScrollToTop /> */}
            <Nav />
            <Routes />
          </Router>
        </>
      )}
    </>
  );
};

export default App;
