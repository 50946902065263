import React, { useEffect, useRef, useState } from "react";

// External components
import ReactPlayer from "react-player";
import { ImPlay2 } from "react-icons/im";
import SharingButtons from "./SharingButtons";
import { useInView } from "react-intersection-observer";

const Video = ({
    url,
    title,
    scrollyTelling,
    scrollyTellingText,
    scrollyTellingVideo,
}) => {
    const [playing, setPlaying] = useState(false);
    const [ref, inView] = useInView();
    const videoWrapper = useRef();
    useEffect(() => {
        if (inView) {
            videoWrapper.current?.querySelector("video").play();
        } else {
            videoWrapper.current?.querySelector("video").pause();
        }
    }, [inView, videoWrapper]);

    return (
        <>

            {!scrollyTelling && (
                <div className="container">
                    <div className="article-container">
                        <figure className="video-container">
                            {url && (
                                <div className="react-wrapper">
                                    {!playing && (
                                        <div
                                            className="player__icon"
                                            onClick={() => setPlaying(true)}
                                        >
                                            <ImPlay2
                                                size="5rem"
                                                color="white"
                                            />
                                        </div>
                                    )}
                                    <ReactPlayer
                                        playing={playing}
                                        onPause={() => setPlaying(false)}
                                        url={url}
                                        config={{
                                            vimeo: {
                                                playerOptions: {
                                                    byline: false,
                                                    color: "ffcc05",
                                                },
                                            },
                                        }}
                                        className="react-player"
                                        width="100%"
                                        height="100%"
                                        // playIcon={<ImPlay2 size="5rem" color="white" />}
                                        // light
                                        controls
                                    />
                                </div>
                            )}

                            <div className="video-info">
                                {title && (
                                    <figcaption className="video-info_title">
                                        {title}
                                    </figcaption>
                                )}
                                {url && <SharingButtons urlToShare={url} />}
                            </div>
                        </figure>
                    </div>
                </div>
            )}
            {scrollyTelling && (
                <section className="video-scrollytelling-wrapper" ref={videoWrapper}>
                    {scrollyTellingVideo.url && (
                        <div className="video-scrollytelling-bg">
                            <div>
                                <video
                                    autoPlay="autoplay"
                                    muted
                                    playsInline
                                    loop
                                    className="video-scrollytelling-bg_video"
                                >
                                    <source
                                        src={scrollyTellingVideo.url}
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                        </div>
                    )}
                    <div className="container">
                        <div ref={ref}
                            className="video-scrollytelling-content article-container"
                            dangerouslySetInnerHTML={{
                                __html: scrollyTellingText,
                            }}
                        ></div>
                    </div>
                </section>
            )}
        </>
    );
};

export default Video;
