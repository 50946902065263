import React, { useEffect } from "react";

// Internal components
import Section from "components/Section";

const Registration = () => {

    useEffect(() => {
        document.body.classList.remove("disable-scroll");
    }, []);
    return (
        <>
            <Section title="Registrering" largeTitle={true}></Section>
        </>
    );
};

export default Registration;
