import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

// Internal components
import Section from "components/Section";
import SEO from "components/SEO";
import Footer from "components/Footer";
import Scrollable from "components/Scrollable/Scrollable";
import ScrollDown from "components/ScrollDown";
// import Article from "components/Article";

const Articles = ({
    title,
    borderColor = "",
    articles = [],
    sectionColor,
    textColor,
    seoTitle,
    seoDescription,
    seoImage,
    page
}) => {
    const { data } = useContext(UmbracoContext);
    const { pathname } = useLocation();
    let currentSection = data.pages.filter((page) => page.url === pathname)[0];
    useEffect(() => {
        document.getElementById("nav-wrapper").style.backgroundColor =
            "transparent";
    }, []);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        document.body.classList.add("disable-scroll");
    }, []);
    let navigate = useNavigate();
    useEffect(() => {
        if (page?.articles?.length === 1) {
            navigate(page.articles[0].url);
        }
    }, [page, navigate]);

    return (
        <>
            <SEO
                title={seoTitle || title}
                description={seoDescription}
                imageUrl={seoImage ? seoImage.url : ""}
            />

            {Array.isArray(articles) && articles.length && <Scrollable returnActiveIndex={val => setActiveIndex(val)}
                sections={[
                    ...articles.map((article, index) => <Section
                        hideBrElementsOnMobile={article.hideBrElementsOnMobile}
                        preTitle={
                            article.preTitle ||
                            (currentSection
                                ? currentSection.preTitle
                                : "")
                        }
                        title={
                            typeof article.title === "object"
                                ? article.btn.to.label
                                : article.title
                        }
                        illustration={
                            Object.keys(article.illustration)
                                .length === 0
                                ? currentSection &&
                                currentSection.illustration
                                : article.illustration
                        }
                        forceLastFrameLottie={
                            index === 0 ? true : false
                        }
                        fullWidthIllustration={true}
                        textColor={textColor}
                        active={index === activeIndex}
                        sectionColor={sectionColor}
                        btn={article.btn}
                        borderColor={
                            borderColor !== "" &&
                                borderColor !== sectionColor
                                ? borderColor
                                : data.mainColor
                        }
                    >     {index === 0 &&
                        <ScrollDown className={"scrolldown--bottom-left"}>scroll ned</ScrollDown>}</Section>),
                    <div data-anchor={"footer"}>
                        <div data-color={textColor} >
                            <Footer
                                textColor={textColor}
                                sectionColor={sectionColor}
                                borderColor={
                                    borderColor !== "" && borderColor !== sectionColor
                                        ? borderColor
                                        : data.mainColor
                                }
                            />
                        </div >
                    </div >
                ]}></Scrollable>}
        </>
    );
};

export default Articles;
