import React from "react";

// External components
import SVG from "react-inlinesvg";

// Icons
import arrow from "assets/short-arrow-left.svg";

const ArrowLeft = ({ size = "1.5rem", className }) => {
    return <SVG src={arrow} width={size} className={className}></SVG>;
};

export default ArrowLeft;
