import React, { createContext, useState, useEffect } from "react";
// import data from "./data.json";

import axios from "axios";

import { useScrollDirection } from 'react-use-scroll-direction';
export const UmbracoContext = createContext();
export const UmbracoProvider = ({ children }) => {
    const [data, setData] = useState(null);

    /* ********************************** */
    /*              GET PAGES             */
    /* ********************************** */

    useEffect(() => {
        axios({
            method: "get",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
                mode: "no-cors",
                cache: "no-cache",
            },
            url: "/headless/data",
        }).then(function (response) {
            // console.log(response.data);
            setData({
                ...response.data,
                textColor: "#2e2d2c",
            });
        });
    }, []);

    /* ********************************** */
    /*            MEDIA QUERIES           */
    /* ********************************** */
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const updateSize = () => {
        setScreenWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", updateSize);
    }, []);

    const [menuOpen, setMenuOpen] = useState(false);


    // ROUTESROUTES

    const [routes, setRoutes] = React.useState();
    const [currentRoute, setCurrentRoute] = React.useState();
    const addValuesToChild = (parent, child) => {
        let obj = { parentKey: parent.key };
        // if (child.type.toLowerCase() === "avdeling") {
        //     obj["ansatteny"] = child?.ansatte?.map((ansatt) => {
        //         return {
        //             ...ansatt,
        //             url: ansatt.url.replace(/(ansatte)/, `$1/${child.slug}`),
        //         };
        //     });
        // }
        return { ...child, ...obj };
    };
    const [parentRoute, setParentRoute] = useState(null);
    const [noMoreRoutes, setNoMoreRoutes] = useState(false);
    useEffect(() => {
        if (noMoreRoutes && routes && currentRoute) {
            let r = routes?.filter((route) =>
                route.children?.filter((child) => child.key === currentRoute.key)
                    .length > 0
                    ? true
                    : false
            );
            if (r.length > 0) {
                setParentRoute(r[0]);
            } else {
                // Reset if not on a subroute
                setParentRoute(null);
            }
        }
    }, [noMoreRoutes, routes, currentRoute]);

    const evaluateChildNodes = React.useCallback((node) => {
        // If children of root has children
        if (node.articles?.length > 0) {
            // Update rooutes by adding articles

            setRoutes((routes) => [
                ...routes,
                ...node.articles.map((child) => addValuesToChild(node, child)),
            ]);
            // Run loop function on articles
            node.articles.forEach((singleNode) => {
                evaluateChildNodes(singleNode);
            });
        } else {
            // No articles, done with loop
            setNoMoreRoutes(true);
        }
    }, []);
    useEffect(() => {
        if (data?.pages) {
            setRoutes(
                (routes) =>
                    Array.isArray(routes) // If routes is array
                        ? [
                            ...routes,
                            ...data.pages.map((child) => addValuesToChild(data, child)),
                        ] // Merge both routes and pages into new array
                        : [
                            data,
                            ...data.pages.map((child) => addValuesToChild(data, child)),
                        ] // If routes is not array, initiate array with root and pages
            );

            // Run loop function on pages
            data.pages.forEach((singleNode) => {
                evaluateChildNodes(singleNode);
            });
        } else {
            setRoutes(data);
        }
    }, [data, evaluateChildNodes]);
    const [scrollDirection, setScrollDirection] = useState();
    const { isScrollingUp, isScrollingDown } = useScrollDirection();

    React.useEffect(() => {
        isScrollingDown && setScrollDirection('down');
        isScrollingUp && setScrollDirection('up');
    }, [isScrollingDown, isScrollingUp]);
    const [scrolled, setScrolled] = useState(0);
    function debounced(delay, fn) {
        let timerId;
        return function (...args) {
            if (timerId) {
                clearTimeout(timerId);
            }
            timerId = setTimeout(() => {
                fn(...args);
                timerId = null;
            }, delay);
        };
    }
    window.addEventListener(
        'scroll',
        debounced(10, () => setScrolled(window.scrollY))
    );
    const [hideNav, setHideNav] = useState(false);
    return (
        <UmbracoContext.Provider
            value={{
                data,
                screenWidth,
                menuOpen,
                setMenuOpen,
                parentRoute,
                setCurrentRoute,
                routes, scrollDirection, currentRoute, scrolled,
                hideNav, setHideNav
            }}
        >
            {children}
        </UmbracoContext.Provider>
    );
};
