import React from "react";
import AngleRight from "./icons/AngleRight";

// External components
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState,
} from "react-accessible-accordion";

const FAQ = ({ items, expandedColor }) => {
    return (
        <>
            {Array.isArray(items) && items.length && (
                <Accordion
                    className="faq"
                    allowMultipleExpanded={true}
                    allowZeroExpanded={true}
                >
                    {items.map((item, index) => (
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <AccordionItemState>
                                        {({ expanded }) => (
                                            <AngleRight
                                                color={
                                                    expanded
                                                        ? expandedColor
                                                        : ""
                                                }
                                                className={`accordion__icon ${
                                                    expanded
                                                        ? "accordion__icon--expanded"
                                                        : ""
                                                }`}
                                            />
                                        )}
                                    </AccordionItemState>
                                    <span>{item.question}</span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: item.answer,
                                    }}
                                ></div>
                            </AccordionItemPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            )}
        </>
    );
};

export default FAQ;
