import React from "react";

// Internal components
import Btn from "components/Btn";

import { FacebookShareButton, TwitterShareButton } from "react-share";

const SharingButtons = ({ urlToShare }) => {
    return (
        <>
            <div className="sharing-buttons">
                <Btn
                    link
                    sm
                    ariaLabel="Trykk her for å dele denne filmen på Facebook"
                >
                    <FacebookShareButton url={urlToShare}>
                        Del på Facebook
                    </FacebookShareButton>
                </Btn>
                <Btn
                    link
                    sm
                    ariaLabel="Trykk her for å dele denne filmen på Twitter"
                >
                    <TwitterShareButton url={urlToShare}>
                        Del på Twitter
                    </TwitterShareButton>
                </Btn>
            </div>
        </>
    );
};

export default SharingButtons;
