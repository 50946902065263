import React from "react";

const RichText = ({ content }) => {
    return (
        <>
            <div
                dangerouslySetInnerHTML={{ __html: content }}
                className="rich-text"
            ></div>
        </>
    );
};

export default RichText;
