import React, { useContext, useEffect, useState } from "react";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

// Internal components
import Split from "components/Split";
import Section from "components/Section";
import SEO from "components/SEO";
import Footer from "components/Footer";

import Scrollable from "components/Scrollable/Scrollable";

const Home = () => {
    const { data } = useContext(UmbracoContext);
    // Set text colors and backgrounds on load

    const [pageColorSet, setPageColorSet] = useState(false);
    useEffect(() => {
        if (!pageColorSet) {
            document.body.style.background = "#fff";
            document.getElementById("nav").style.color = "#2e2d2c";
            setPageColorSet(true);
        }
    }, [pageColorSet]);
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        document.body.classList.add("disable-scroll");
    }, []);

    return (
        <>
            {data !== null && (
                <>
                    <SEO
                        title={data.seoTitle || data.title}
                        description={data.seoDescription}
                        imageUrl={data.seoImage ? data.seoImage.url : ""}
                    />
                    <Scrollable returnActiveIndex={val => setActiveIndex(val)}
                        sections={[
                            <header className="header" data-anchor={"header"}>
                                <div data-color="#000"
                                    bg-color={data.mainColor}>
                                    <Split
                                        title={data.headerTitle}
                                        h1={true}
                                        textColor={data.textColor}
                                        borderColor={data.mainColor}
                                        text={data.headerText}
                                        sectionColor="#fff"
                                        scrollDown="scroll ned"
                                    />
                                </div >
                            </header>,
                            ...data.pages.filter(p => p.showInNav)?.map(
                                (section, index) =>
                                    section.title && (
                                        <div data-anchor={section.key} key={section.key}>
                                            <Section
                                                dataAnchor={section.key}
                                                data-anchor={section.key}
                                                preTitle={section.preTitle || ""}
                                                title={section.title || ""}
                                                btn={section.btn}
                                                slug={section.slug}
                                                sectionColor={section.sectionColor || ""}
                                                illustration={section.illustration || {}}
                                                fullWidthIllustration={true}
                                                active={index + 1 === activeIndex}
                                                hideBrElementsOnMobile={section.hideBrElementsOnMobile}
                                                textColor={section.textColor || ""}
                                                // sneakPeak={index === 0 ? false : true}
                                                borderColor={
                                                    section.borderColor ||
                                                    (section.sectionColor !== data.mainColor
                                                        ? data.mainColor
                                                        : section.borderColor)
                                                }
                                            />
                                        </div >
                                    )
                            ),
                            <div data-anchor={"footer"}>
                                <div data-color="#2e2d2c" bg-color="#fff">
                                    <Footer
                                        sectionColor="#fff"
                                        textColor="#2e2d2c"
                                        borderColor="#ffcc05"
                                    />
                                </div >
                            </div >
                        ]}
                    />
                </>
            )}
        </>
    );
};

export default Home;
