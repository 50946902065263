import { useEffect } from 'react';

// External components
import { useQuery } from 'react-query';

const Loader = ({ query, endpoint, children, setState }) => {
    const { isLoading, error, data } = useQuery(query, () =>
        fetch(endpoint).then(res =>
            res.json()
        )
    );
    useEffect(() => {
        if (data) {
            setState(data);
        }
    }, [data, setState]);
    if (isLoading) return <div className="loading-screen">Laster inn...</div>;
    if (error) return <div className="error-screen">An error has occurred: {error.message}</div>;
    return (
        <>{children}</>
    );
};

export default Loader;