import React, { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router-dom";

// Internal components
import Logo from "components/Logo";
import InternalOrExternalLink from "components/InternalOrExternalLink";

// External components
import Hamburger from "hamburger-react";
import Hotkeys from 'react-hot-keys';

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

const Nav = () => {
    const { data, menuOpen, setMenuOpen, scrollDirection, scrolled, hideNav } = useContext(UmbracoContext);
    const { pathname } = useLocation();

    // Hide menu on scroll
    // const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    // Disable body scroll if menu open
    useEffect(() => {
        if (menuOpen) {
            document.body.classList.add("no-scroll");
            document.getElementById("nav").style.color = "#2e2d2c";
            document.getElementById("nav").style.borderColor = "#2e2d2c";
            document.getElementById("nav-wrapper").style.backgroundColor =
                "#fff";
        } else if (!menuOpen) {
            document.body.classList.remove("no-scroll");
        }
    }, [menuOpen]);

    // Hide nav on scroll down, show on scroll up
    useEffect(() => {
        const handleScroll = () => {
            // find current scroll position
            const currentScrollPos = window.pageYOffset;
            currentScrollPos > 50 && setVisible(false);
            currentScrollPos < 50 && setVisible(true);

            // setVisible(currentScrollPos < prevScrollPos ? true : false);

            // // set state to new scroll position
            // setPrevScrollPos(currentScrollPos);
        };
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, [visible]);
    useEffect(() => {
        if (!menuOpen) {
            let color = document.querySelector(".swiper-slide.swiper-slide-active")?.querySelector("[data-color]")?.getAttribute("data-color");
            if (color) {
                document.querySelector('#nav').style.color = color;
            }
        }
    }, [menuOpen]);

    return (
        <>
            <Hotkeys
                keyName='esc'
                allowRepeat={true}
                onKeyDown={() => {
                    setMenuOpen(false);
                }}
            >

            </Hotkeys>
            {data !== null && data !== undefined && (
                <div
                    className={`nav-wrapper ${menuOpen ? "nav-wrapper--open" : ""
                        }
                    ${(scrollDirection === "down" && scrolled > 50 && !menuOpen) ? "nav-wrapper--hide" : ""}
                    ${(hideNav && scrollDirection === "down" && scrolled > 50 && !menuOpen) ? "nav-wrapper--hide-mobile" : ""}
                    `}
                    id="nav-wrapper"
                >
                    <nav
                        id="nav"
                        className="nav"
                        tabIndex={1}
                        onClick={() => (menuOpen ? setMenuOpen(false) : null)}
                    >
                        <Logo className="nav_logo" />
                        <div className="nav_hamburger">
                            <Hamburger
                                size="30"
                                toggled={menuOpen}
                                toggle={setMenuOpen}
                            />
                        </div>
                    </nav>
                    <div
                        className={`menu ${menuOpen ? "menu--open" : ""}`}
                        onClick={() => setMenuOpen(false)}
                    >
                        <div className="container">
                            <p className="pre-title">Menyoversikt</p>
                            <ul className="menu_items">
                                {data.hovedmeny &&
                                    data.hovedmeny?.map(
                                        (p, index) =>
                                            p.label && (
                                                <li
                                                    className={`menu_items_item ${pathname.includes(
                                                        p.url
                                                    )
                                                        ? "menu_items_item--current"
                                                        : ""
                                                        }`}
                                                    key={
                                                        index +
                                                        "menu_items_item"
                                                    }
                                                >
                                                    <InternalOrExternalLink
                                                        to={p.url}
                                                        style={{
                                                            borderColor:
                                                                data.mainColor,
                                                        }}
                                                    >
                                                        {p.label.replace(
                                                            /(<([^>]+)>)/gi,
                                                            ""
                                                        )}
                                                    </InternalOrExternalLink>
                                                </li>
                                            )
                                    )}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Nav;
